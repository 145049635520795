@import "../imports";

.web-footer {
    position: relative;
    display: flex;
    justify-content: center;

    .web-footer {
        &__bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 3;
            background-image: $gradient__alfa;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: right bottom;
                mix-blend-mode: luminosity;
                opacity: 0.07;
            }
        }

        &__container {
            z-index: 4;
            color: $color__omega;
            padding: sizer(50) 0 0;
            position: relative;
            width: sizer(1000);

            @include respond-to("-screen-xl") {
                width: 100%;
                padding: sizer(50) sizer(50) 0;
            }

            @include respond-to("-screen-md") {
                padding: sizer(40) sizer(40) 0;
            }

            @include respond-to("-screen-md") {
                padding: sizer(40) sizer(30) 0;
            }

            @include respond-to("-screen-xs") {
                padding: sizer(40) sizer(20);
            }
        }

        &__row {
            margin: 0 sizer(-75);
            display: flex;
            flex-wrap: wrap;

            @include respond-to("-screen-lg") {
                margin: 0 sizer(-35);
            }

            @include respond-to("-screen-md") {
                margin: 0 sizer(-15);
            }

            @include respond-to("-screen-sm") {
                // flex-wrap: wrap;
                flex-direction: column-reverse;
                margin: 0;
            }
        }

        &__col {
            width: 50%;
            flex: 0 0 50%;
            padding: 0 sizer(75);

            @include respond-to("-screen-lg") {
                padding: 0 sizer(35);
            }

            @include respond-to("-screen-md") {
                padding: 0 sizer(15);
            }

            @include respond-to("-screen-sm") {
                width: 100%;
                flex: 0 0 100%;
            }

            &--form {
                @include respond-to("-screen-sm") {
                    width: 100%;
                    padding: 0;
                    padding-top: sizer(50);
                }
            }

            &--info {
                padding-right: sizer(100);

                @include respond-to("-screen-md") {
                    padding-right: 0;
                }

                @include respond-to("-screen-sm") {
                    padding: 0;
                    width: 100%;
                }

                img {
                    width: sizer(35);
                    flex: 0 0 sizer(35);
                    height: sizer(35);
                    margin-right: sizer(10);
                }
            }
        }

        &__content {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;

            a {
                display: flex;
                align-items: center;
                margin-top: sizer(8);
            }
        }

        &__thanks {
            position: absolute;
            left: 0;
            top: 0;
            background-color: $color__omega;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            z-index: 100;
            box-shadow: sizer(5) sizer(5) sizer(10) #00000069;
            color: $color__alfa;

            button {
                background-color: $color__omega;
                border: sizer(2) solid $color__beta;
                margin-top: sizer(20);
                padding: sizer(10) sizer(35);
                text-transform: uppercase;
                color: $color__beta;
                cursor: pointer;
                transition: all 0.2s ease-out;

                &:hover,
                &:focus,
                &:active {
                    background-color: $color__beta;
                    color: $color__omega;
                }
            }
        }

        &__form {
            position: relative;

            &-header {
                margin: 0 0 sizer(25);
            }

            .form {
                &__sent {
                    display: none !important;
                }

                &__required {
                    margin: sizer(10) 0 0;
                    padding-left: sizer(32);
                }

                &__group {
                    margin: 0 0 sizer(12);
                    position: relative;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                &__control {
                    height: sizer(50);
                    background: $color__omega;
                    border: none;
                    font-size: text-sizer(18);
                    font-weight: $weight__regular;
                    font-family: $font__alfa;
                    $color: $color__psi;
                    padding: sizer(15) sizer(20);
                    width: 100%;

                    @include respond-to("-screen-xl") {
                        font-size: text-sizer(16);
                    }

                    @include respond-to("-screen-md") {
                        font-size: text-sizer(14);
                    }

                    &::-webkit-input-placeholder {
                        color: $color;
                        font-size: text-sizer(17);

                        @include respond-to("-screen-xxxl") {
                            font-size: text-sizer(16);
                        }

                        @include respond-to("-screen-xxl") {
                            font-size: text-sizer(15);
                        }

                        @include respond-to("-screen-xl") {
                            font-size: text-sizer(14);
                        }

                        @include respond-to("-screen-lg") {
                            font-size: text-sizer(13);
                        }

                        @include respond-to("-screen-md") {
                            font-size: text-sizer(12);
                        }
                    }
                    &:-ms-input-placeholder {
                        color: $color;
                        font-size: text-sizer(17);

                        @include respond-to("-screen-xxxl") {
                            font-size: text-sizer(16);
                        }

                        @include respond-to("-screen-xxl") {
                            font-size: text-sizer(15);
                        }

                        @include respond-to("-screen-xl") {
                            font-size: text-sizer(14);
                        }

                        @include respond-to("-screen-lg") {
                            font-size: text-sizer(13);
                        }

                        @include respond-to("-screen-md") {
                            font-size: text-sizer(12);
                        }
                    }
                    &:-moz-placeholder {
                        color: $color;
                        font-size: text-sizer(17);

                        @include respond-to("-screen-xxxl") {
                            font-size: text-sizer(16);
                        }

                        @include respond-to("-screen-xxl") {
                            font-size: text-sizer(15);
                        }

                        @include respond-to("-screen-xl") {
                            font-size: text-sizer(14);
                        }

                        @include respond-to("-screen-lg") {
                            font-size: text-sizer(13);
                        }

                        @include respond-to("-screen-md") {
                            font-size: text-sizer(12);
                        }
                    }

                    &:required + label {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: sizer(1);
                        height: 100%;

                        &::after {
                            content: "*";
                            position: absolute;
                            top: 50%;
                            right: sizer(15);
                            font-size: text-sizer(28);
                            transform: translate(0, -35%);
                            z-index: 5;
                            color: $color__beta;
                            line-height: 1em;
                        }
                    }
                }

                &__button {
                    width: 100%;
                    height: sizer(50);
                    font-size: text-sizer(14);
                    text-transform: uppercase;
                    font-weight: $weight__bold;
                    position: relative;
                    z-index: 2;
                    background: transparent;
                    border: none;
                    color: $color__omega;
                    cursor: pointer;
                    margin: sizer(20) 0 0;

                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        z-index: -1;
                        background: $color__omega;
                        opacity: 0.2;
                    }
                }
            }

            textarea.form__control {
                height: sizer(155);

                &:required + label {
                    &::after {
                        top: sizer(25);
                    }
                }
            }
        }

        &__content {
            h3 {
                font-weight: $weight__bold;
            }
        }

        &__signature {
            text-align: center;
            color: $color__omega;
            font-size: text-sizer(12);
            font-weight: $weight__light;
            // font-family: $font__beta;
            padding: sizer(70) 0 sizer(20);
            opacity: 0.7;
            display: flex;
            justify-content: center;

            @include respond-to("-screen-xs") {
                padding-bottom: sizer(5);
                flex-direction: column;
                align-items: center;
                width: 100%;
            }

            .signature__link {
                padding-right: sizer(10);
                margin-right: sizer(10);
                position: relative;
                transition: all 0.2s ease-out;

                @include respond-to("-screen-xs") {
                    padding-bottom: sizer(10);
                    padding-right: 0;
                    margin-right: 0;
                }

                &:hover,
                &:focus,
                &:active {
                    text-shadow: sizer(1) 0 0 $color__omega;
                }

                &::after {
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 50%;
                    height: 1.2em;
                    width: sizer(1);
                    background-color: $color__omega;
                    transform: translateY(-50%);

                    @include respond-to("-screen-xs") {
                        content: none;
                    }
                }
            }
        }

        &__title {
            display: flex;
            flex-direction: column;

            &::after {
                content: "";
                display: block;
                width: 100%;
                height: sizer(1);
                opacity: 0.4;
                background: $color__omega;
                margin: sizer(20) 0;
            }
        }
    }
}

input[type="checkbox"] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;

    &:checked + label {
        &::after {
            opacity: 1;
            visibility: visible;
        }
    }
}

.checkbox {
    &__alfa {
        padding: 0 sizer(20) 0 sizer(25);
        cursor: pointer;
        position: relative;
        display: block;
        font-weight: $weight__light;
        font-size: text-sizer(16);
        line-height: 1em;
        // font-family: $font__beta;

        @include respond-to("screen-xxl") {
            padding: 0 sizer(20) 0 sizer(32);
        }

        @include respond-to("-screen-xl") {
            font-size: text-sizer(14);
        }

        @include respond-to("-screen-lg") {
            font-size: text-sizer(12);
        }

        @include respond-to("-screen-md") {
            font-size: text-sizer(10);
        }

        &::before,
        &::after {
            content: "";
            display: block;
            position: absolute;
        }

        &::before {
            background: transparent;
            left: 0;
            top: sizer(2);
            border: sizer(2) solid $color__omega;
            width: sizer(16);
            height: sizer(16);
        }

        &::after {
            left: sizer(4);
            top: sizer(6);
            opacity: 0;
            visibility: hidden;
            background: $color__omega;
            transition: all 0.2s ease-out;
            width: sizer(12);
            height: sizer(12);
        }
    }
}
